export class UserCredentials {
    username: string;
    password: string;
}

export class PasswordDetails {
    firstname: string;
    password: string;
    repeatPassword: string;
}
