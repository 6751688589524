
export const environment = {
version: '2.3.7',
//PROD
production: true,
apiURL: 'https://gandalf.lndr.credit/',
finduis: 'https://prod.finduis.com/fineract-provider/api/v1/',

//UAT
//production: true,
//apiURL: 'https://gandalfuat.uloans.co/',
//finduis: 'https://finduistest.lnder.co.za/fineract-provider/api/v1/'

//DEV
//production: false,
//apiURL: 'http://localhost:5070/',
//finduis: 'https://finduistest.lnder.co.za/fineract-provider/api/v1/'
};

