import { AfterViewChecked, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ProductSelectService } from 'app/ts/services/product-select.service';
import { IdentificationTypeService } from 'app/ts/services/identification-type.service';


@Component({
  selector: 'app-client-capture',
  templateUrl: './client-capture.component.html',
  styleUrls: ['./client-capture.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-50%)' }),
        animate('100ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('100ms ease-in', style({ transform: 'translateX(-50%)' }))
      ])
    ])
  ]
})

@ViewChild('picker', { read: undefined, static: false })
export class ClientCaptureComponent implements OnInit, AfterViewChecked {
  clientDetailsForm: FormGroup;
  bankDetails: FormGroup;
  employmentDetails: FormGroup;
  uploadDocumentsForm: FormGroup;
  selectedIndex: number;
  formStep: number;
  currentRoute: string;
  showProductSelection: boolean = true;
  clientId: number;
  isPassport$ = this._identityType.isPassport;
  productList$ = this._productSelect.productList;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _productSelect: ProductSelectService,
    public _identityType: IdentificationTypeService
  ) {}

  ngOnInit(): void {
    this._productSelect.updateLoanAmount(0);
    this._productSelect.updateLoanTerm(0);
    this._productSelect.updateLoanType("Short Term Loan");

    this.clientDetailsForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      title: ['', Validators.required],
      mobileNumber: ['1', Validators.required],
      email: ['', Validators.required],
      workNumber: [''],
      idNumber: ['', Validators.required],
      dateOfBirth: ['', Validators.required]
    });

    this.selectedIndex = 0;
  }

  ngAfterViewChecked(): void {
    var staffId = sessionStorage.getItem('staffId');
    //capture logic
    if(Number(sessionStorage.getItem("clientId")) == 0){

      //search logic
      this.route.params.subscribe(params => {
      if (this.clientId != params['clientId']) {
        this.clientId = +params['clientId'];
        sessionStorage.setItem('searchClientID', this.clientId.toString());
     

        //if (this.clientId != 0) {
          this.showProductSelection = false;
        //} 
        //else {
        //  if(staffId == '630' || staffId == '607' || staffId == '650')
        //  {
        //    this.showProductSelection = true;
        //  }
        //}


      }
    });
    }
   
    //if(staffId == '630' || staffId == '607' || staffId == '650')
    //{
    //  this.showProductSelection = true;
    //}else{
      this.showProductSelection = false;
    //}
  }

  findClientTriggered() {
    var idNumber = sessionStorage.getItem('searchClientID');

    if (idNumber != '' && idNumber != null) {
      this.showProductSelection = false;
    }
    else {
      this.showProductSelection = true;
    }
  }

  public previousStep() {
    this.selectedIndex -= 1;
  }

  updateStep() {
    
    if (this.selectedIndex == 5) {
      this.reload();
    }
    else {
      this.selectedIndex += 1;
    }
  }

  onChange() {
    this.clientId = Number(sessionStorage.getItem("clientId"));
  }

  reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], { relativeTo: this.route });
  }
}
