<form [formGroup]="bankDetails" novalidate>
    <div class="row row-cols-1 row-cols-md-4">
        <div class="col">
            <mat-form-field appearance="standard" >
                <mat-label class="mat-label-margin">Bank</mat-label>
                <mat-select formControlName="bank" [disabled]="clientId !== 0">
                    <mat-option *ngFor="let b of banks" [value]="b.id">
                        {{b.bankName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Account Number</mat-label>
                <input matInput class="mat-textbox" input name="AccountNumber" type="number" formControlName="accountNumber" autocomplete="nope">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Account Type</mat-label>
                <mat-select name="you" formControlName="accountType" [disabled]="clientId !== 0">
                    <mat-option *ngFor="let accountType of accountTypes" [value]="accountType.id">
                        {{accountType.accountTypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Branch Code</mat-label>
                <input matInput class="mat-textbox" input name="branchCode" type="number" formControlName="branchCode" autocomplete="nope">
            </mat-form-field>
        </div>
    </div>
    <div class="row row-cols-1 row-cols-md-3">
        <div class="col">
            <mat-checkbox formControlName="statementAge" labelPosition="before" [disabled]="clientId !== 0">Bank statement is not older than seven days</mat-checkbox>
        </div>
        <div class="col">
            <mat-checkbox formControlName="salaries" labelPosition="before" [disabled]="clientId !== 0">Bank statement reflects latest three months' salaries</mat-checkbox>
        </div>
        <div class="col">
            <mat-checkbox formControlName="owner" labelPosition="before" [disabled]="clientId !== 0">Bank statement belongs to the customer</mat-checkbox>
        </div>
    </div>
</form>
<br/>