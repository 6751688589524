<form>
    <div class="row">
        <div class="col-12">
            <h3>Log In</h3>
        </div>
        <div class="col-12">
            <mat-form-field appearance="standard">
                <mat-label>Username</mat-label>
                <input matInput required intput name="username" input type="text" [(ngModel)]="credentials.username">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="standard">
                <mat-label>Password</mat-label>
                <input matInput #password name="password" required type="{{showDetails ? 'text' : 'password'}}" [(ngModel)]="credentials.password">
                <mat-icon matSuffix (click)="showDetails = !showDetails">{{showDetails ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 py-0 my-1">
            <button type="submit" class="button sign-in-button flex card card-body mat-textbox" (click)="signIn()">
                <h3 class="button-text">
                    <b>Sign In</b>
                </h3>
            </button>
        </div>

    </div>
</form>