import { Component, OnInit } from '@angular/core';

import { ApiService } from 'app/ts/ApiService';
import { Router } from '@angular/router';
import { PasswordDetails, UserCredentials } from 'app/ts/models/user-credentials';
import { Authentication } from 'app/ts/models/broker-interfaces';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { PendingApprovalLoansService } from 'app/ts/services/pending-approval-loans.service';
import { ProductSelectService } from 'app/ts/services/product-select.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  passwordDetails: PasswordDetails;
  showDetails: boolean;
  credentials = new UserCredentials();

  constructor(
    private api: ApiService,
    private router: Router,
    private snackbar: MatSnackBar, 
    private _pendingLoansService: PendingApprovalLoansService,
    private _productSelectService: ProductSelectService
  ) {
  }


  ngOnInit() {
    this.showDetails = false;
  }

  signIn(): void {
    this.api.login(this.credentials).subscribe((data: Authentication) => {
      if (data.authenticated) {
        if (!data.shouldRenewPassword) {
          data.roles.forEach(function (c) {
            switch (c.id) {
              case 1: {
                sessionStorage.setItem('isSuper', 'true');
                break;
              }
              case 2: {
                sessionStorage.setItem('isSelfService', 'true');
                break;
              }
              case 3: {
                sessionStorage.setItem('isWebUsr', 'true');
                break;
              }
              case 4: {
                sessionStorage.setItem('isBroker', 'true');
                break;
              }
            }
          });

          sessionStorage.setItem('userId', data.userId.toString());
          sessionStorage.setItem('staffId', data.staffId.toString());
          sessionStorage.setItem('authenticated', 'true');

          let staffId = Number(sessionStorage.getItem('staffId'));
          
          //if(data.staffId == 630 || data.staffId == 607)
          //{
          //  this._pendingLoansService.updatePendingLoanList(staffId);
          //  this._productSelectService.getStaffProductList();
          //}
          

          

          var minutesToAdd = 60;
          var currentDate = new Date();
          var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

          sessionStorage.setItem('sessionExpiresAt', futureDate.toString());

          this.openSnackBar('Successfully logged in.', 'success-snackbar');
          this.router.navigate(['broker/dashboard'], { queryParams: { userId: data.userId } });
        } else {
          sessionStorage.setItem('userId', data.userId.toString());
          this.openSnackBar('Your password has expired, please reset your password', 'error-snackbar');
          this.router.navigate(['broker/auth/renew'], { queryParams: { userId: data.userId } });
        }
      }
      else {
        this.openSnackBar('Username/Password Incorrect.', 'error-snackbar');
      }

    });
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
