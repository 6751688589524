<form [formGroup]="empDetails" novalidate>
    <div class="row row-cols-1 row-cols-md-3">
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Employer Name</mat-label>
                <input matInput class="mat-textbox" input name="empName" input type="text" formControlName="employer_Name" autocomplete="nope">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Employer Contact No</mat-label>
                <input matInput class="mat-textbox" input name="empContactNo" input type="text" formControlName="employer_Contact_Number" autocomplete="nope">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Employee No</mat-label>
                <input matInput class="mat-textbox" input name="empNo" input type="text" formControlName="employee_Number" autocomplete="nope">
            </mat-form-field>
        </div>
    </div>
    <div class="row row-cols-1 row-cols-md-3">
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Date Started</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="employment_Start_Date">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Gross Salary</mat-label>
                <input matInput class="mat-textbox" input name="Gross" input type="number" formControlName="gross_salary" autocomplete="nope">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Net Salary</mat-label>
                <input matInput class="mat-textbox" input name="Net" input type="number" formControlName="net_salary" autocomplete="nope">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Salary Date</mat-label>
                <mat-select formControlName="employer_Salary_Date">
                    <mat-option *ngFor="let date of salaryDates" [value]="date">
                        {{date}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>