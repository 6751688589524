import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../ApiService';
import { Products } from '../models/broker-interfaces';
import { LoanApplication } from '../models/loan-classes';

@Injectable({
  providedIn: 'root'
})
export class ProductSelectService {


  private readonly intitalLoanApplicationState: LoanApplication = {} as LoanApplication;
  private readonly initialProductList: Products[] = [];

  private readonly initalLoanType: string = "";
  private readonly initalLoanAmount: number = 0;
  private readonly initalLoanTerm: number = 0;
  private readonly initialProduct: string = "";


  loanApplicationSubject$ = new BehaviorSubject(this.intitalLoanApplicationState);
  loanApplication$ = this.loanApplicationSubject$.asObservable();

  productListSubject$ = new BehaviorSubject(this.initialProductList);
  productList$ = this.productListSubject$.asObservable();

  constructor(private api: ApiService) {
    this.loanApplication$.subscribe((x: LoanApplication) => {
      if (x !== undefined && x !== null ) {
        localStorage.setItem("loanAppState", JSON.stringify(x));
      }
    });

    this.productList$.subscribe((x: Products[]) => {
      if (x !== undefined && x !== null) {
        localStorage.setItem("productList", JSON.stringify(x));
      }
    });
  }
//
  //getStaffProductList() {
  //  var staffId = sessionStorage.getItem('staffId');
  //  if(staffId == '630' || staffId == '607' || staffId == '650')
  //  {
  //    this.api.getProducts(Number(sessionStorage.getItem("staffId"))).subscribe((data: Products[]) => {
  //      this.updateProductList(data);
  //      this.updateSelectedProductCategory(data[0].productCategory);
  //    });
  //  }
//
  //};
//
  resetLoanApplication() {
    this.loanApplicationSubject$.next(this.intitalLoanApplicationState);
  }

  updateProductList(newValues: Products[]) {
    if (this.productListSubject$.value != newValues)
      this.productListSubject$.next(newValues);
  }

  updateLoanType(newValues: string) {
    if (this.loanApplicationSubject$.value.type != newValues)
      this.loanApplicationSubject$.next({ ...this.loanApplicationSubject$.value, type: newValues });
  }

  updateLoanAmount(newValues: number) {
    if (this.loanApplicationSubject$.value.amount != newValues)
      this.loanApplicationSubject$.next({ ...this.loanApplicationSubject$.value, amount: newValues });
  }

  updateLoanTerm(newValues: number) {
    if (this.loanApplicationSubject$.value.term != newValues)
      this.loanApplicationSubject$.next({ ...this.loanApplicationSubject$.value, term: newValues });
  }

  updateSelectedProductCategory(newValues: string) {
    if (this.loanApplicationSubject$.value.selectedProduct != newValues)
      this.loanApplicationSubject$.next({ ...this.loanApplicationSubject$.value, selectedProduct: newValues });
  }

  get productList() {
    return this.productListSubject$.value;
  }

  get loanApplication() {
    return this.loanApplicationSubject$.value;
  }

}
