import { AfterViewChecked, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { ApiService } from 'app/ts/ApiService';
import { DocumentUploadList } from 'app/ts/models/broker-interfaces';
import { LoanApplication } from 'app/ts/models/loan-classes';
import { IdentificationTypeService } from 'app/ts/services/identification-type.service';
import { ProductSelectService } from 'app/ts/services/product-select.service';
import { environment } from 'environments/environment';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss', '../../forms.shared.scss']
})
export class UploadDocumentsComponent implements OnInit, AfterViewChecked {
  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();


  loanApplication$ = this._productSelect.loanApplication;
  isPassport$ = this._identityType.isPassport;

  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiURL;
  maximumFileSize = 40 * 1024 * 1024; // File size in MB

  selectedDocument: string;
  selectedDocumentDescription: string;
  documents: DocumentUploadList[];
  form: FormGroup;
  loanType: string;
  clientId: number = 0;
  staffId: number = 0;

  constructor(private api: ApiService,
    private snackbar: MatSnackBar,
    public formBuilder: FormBuilder,
    public _productSelect: ProductSelectService,
    public _identityType: IdentificationTypeService) {

    this._productSelect.loanApplication$.subscribe((x: LoanApplication) => {
      if (x !== undefined || x !== null) {
        if (this.loanApplication$ != x) {
          this.loanApplication$ = x;
          this.setDocumentList();
        }
      }
    });

    this._identityType.isPassport$.subscribe((x: null) => {
      if (x !== undefined || x !== null) {
        if (this.isPassport$ != x) {
          this.isPassport$ = x;
          this.setDocumentList();
        }
      }
    });

    this.setDocumentList();

    this.selectDocument(0);
  }

  setDocumentList() {
    this.documents = [
      { key: 'bankstatement', description: '3 Months Bank Statements', isUploaded: false },
      { key: "payslip", description: 'Latest Payslip', isUploaded: false },
      { key: 'applicationform', description: 'Loan Application Form', isUploaded: false },
      { key: 'additional', description: 'Additional Forms', isUploaded: false }
    ];

    if (this.isPassport$) {
      this.documents.splice(0, 0, { key: 'id', description: 'Passport Copy', isUploaded: false })
      this.documents.splice(1, 0, { key: 'workpermit', description: "Work Permit", isUploaded: false });
    } else {
      this.documents.splice(0, 0, { key: 'id', description: 'Id Copy', isUploaded: false })
    }

    if (this.loanApplication$.type == "Debt Consolodation Loan") {
      if (this.isPassport$) {
        this.documents.splice(5, 0, { key: 'settlementletters', description: 'Settlement Letters', isUploaded: false });
      } else {
        this.documents.splice(4, 0, { key: 'settlementletters', description: 'Settlement Letters', isUploaded: false });
      }

    }

  }

  ngAfterViewChecked(): void {

    if (this.clientId == 0) {
      if (Number(sessionStorage.getItem('clientId')) > 0) {
        this.clientId = Number(sessionStorage.getItem('clientId'));
        this.uploader.options.url = `${this.baseUrl}Portal/upload-client-documents/${this.clientId}/${this.staffId}/${this.selectedDocument}`
      }

    }
  }

  ngOnInit(): void {

  }

  initializeUploader() {
    this.clientId = Number(sessionStorage.getItem('clientId'));
    this.staffId = Number(sessionStorage.getItem("staffId"));

    this.uploader = new FileUploader({
      url: `${this.baseUrl}Portal/upload-client-documents/${Number(sessionStorage.getItem('clientId'))}/${Number(sessionStorage.getItem("staffId"))}/${this.selectedDocument}`,
      isHTML5: true,
      allowedFileType: ['pdf', 'image'],
      removeAfterUpload: false,
      autoUpload: true,
      maxFileSize: this.maximumFileSize,
    });

    this.uploader.onProgressItem = (file) => {
      //file.withCredentials = false;


      let index = this.uploader.queue.indexOf(file);
      this.uploader.queue[index].progress = 0;
      this.uploadFilesSimulator(index, this.uploader.queue);
    }



    this.uploader.onSuccessItem = (item, response, status, headers) => {


      if (status = 204) {
        this.openSnackBar(`${this.selectedDocumentDescription} Successfully uploaded.`, "success-snackbar");
        let updateItem = this.documents.find(this.findIndexToUpdate, this.selectedDocument);
        let index = this.documents.indexOf(updateItem);

        this.documents[index].isUploaded = true;
      }
    }

    this.uploader.onErrorItem = (item, response, status, headers) => {
      let message = `Could not upload the selected ${this.selectedDocumentDescription}`;

      if (response) {
        message = `${message}, ${response}`;
      }

      this.openSnackBar(message, "error-snackbar");
    }

    // this.uploader.response.subscribe(i => this.response)

  }

  selectDocument(i: number) {
    this.selectedDocument = this.documents[i].key;
    this.selectedDocumentDescription = this.documents[i].description;
    this.initializeUploader()
  }

  fileOverBase(e: any) {
    this.hasBaseDropZoneOver = e;
  }

  findIndexToUpdate(newItem) {
    return newItem.key === this;
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number, fileArray: any[]) {

    setTimeout(() => {
      if (index === fileArray.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (fileArray[index].progress === 100) {
            console.log("here?");
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1, fileArray);
          } else {
            console.log("progress2", fileArray[index].progress)
            fileArray[index].progress += 5;
          }
        }, 50);
      }
    }, 1000);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  back(): void {
    this.previousStepEvent.emit();
  }

  finish(): void {
    if (this.docValidationId() && this.docValidationBankStatement() && this.docValidationPayslip()) {
      this.api.sendUlEmail(Number(sessionStorage.getItem("staffId")), Number(sessionStorage.getItem("clientId"))).subscribe(() => {
        this.openSnackBar('Congratulations you have successfully submitted your application please proceed with your next customer', 'success-snackbar');
      });
      this.updateStepEvent.emit()
    } else {
      if (!this.docValidationId()) {
        this.openSnackBar('Please make sure the ID or Passport documents are uploaded.', 'error-snackbar');
      }
      if (!this.docValidationBankStatement()) {
        this.openSnackBar('Please make sure the Bank Statement document is uploaded.', 'error-snackbar');
      }
      if(!this.docValidationPayslip()) {
        this.openSnackBar('Please make sure the Payslip document is uploaded.', 'error-snackbar');
      }

    }
  }

  docValidationId() {
    let result: Boolean = false;
    this.documents.forEach(doc => {
      if (doc.key == 'id') {
        if (doc.isUploaded) {
          result = true;
        }
      }
    })
    return result;
  }

  docValidationBankStatement() {
    let result: Boolean = false;
    this.documents.forEach(doc => {
      if (doc.key == 'bankstatement') {
        if (doc.isUploaded) {
          result = true;
        }
      }
    })
    return result;
  }

  docValidationPayslip() {
    let result: Boolean = false;
    this.documents.forEach(doc => {
      if (doc.key == 'payslip') {
        if (doc.isUploaded) {
          result = true;
        }
      }
    })
    return result;
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
