import { BrokerFormsModule } from 'app/forms/broker-portal/broker-forms.module';
import { HttpClientModule } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { BatchUploadComponent } from 'app/components/broker-portal/batch/batch-upload.component';
import { BrokerDashboardComponent } from 'app/components/broker-portal/broker-dashboard/broker-dashboard.component';
import { ClientCaptureComponent } from 'app/components/broker-portal/client-capture/client-capture.component';
import { BatchDialogComponent } from './batch-dialog/batch-dialog.component';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NavbarAuthComponent } from './authenticate/navbar-auth/navbar-auth.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { BarChartXComponent } from '../bar-chart-x/bar-chart-x.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ChartistModule } from "ng-chartist";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BarChartComponent } from '../bar-chart/bar-chart.component';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { BankEmployerDetailsComponent } from './bank-employer-details/bank-employer-details.component';
import { LoanComponent } from './loan/loan.component';
import { MatSliderModule } from '@angular/material/slider';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FileUploadModule } from 'ng2-file-upload';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { LoanPendingApprovalComponent } from './loan-pending-approval/loan-pending-approval.component';
import { CurrencyFormatterModule } from '../currency-formatter/currency-formatter.component.module';
import { LoanDialogComponent } from './loan-pending-approval/loan-dialog/loan-dialog.component';
import { NotesComponent } from './notes/notes.component';
import { ProductSelectorComponent } from './product-selector/product-selector.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrokerFormsModule,
    MatTabsModule,
    MatToolbarModule,
    ChartsModule,
    ChartistModule,
    MatDialogModule,
    SweetAlert2Module,
    TabsModule.forRoot(),
    MatSliderModule,
    MatPaginatorModule,
    MatTableModule,
    CurrencyFormatterModule,
    NgCircleProgressModule.forRoot({
      "radius": 60,//60,
      "space": -20,//-10,
      "outerStrokeGradient": true,
      "outerStrokeWidth": 10, //10
      "outerStrokeColor": '#1976d2',
      "outerStrokeGradientStopColor": "#1955A0",
      "innerStrokeColor": "#e7e8ea",
      "innerStrokeWidth": 30, //10
      "title": 'auto',
      "toFixed": 0,
      "subtitle": "Completed",
      "unitsFontSize": "25",
      "unitsFontWeight": "500",
      "unitsColor": "#6c757d",
      "titleFontSize": "25",
      "titleFontWeight": "500",
      "titleColor": "#6c757d",
      "animateTitle": true,
      "animationDuration": 300,
      "showTitle": true,
      "showSubtitle": false,
      "showUnits": true,
      "clockwise": false,
      "responsive": true,
      "lazy": false,
      "outerStrokeLinecap": 'butt'
    }),
    FileUploadModule
  ],
  declarations: [
    NavbarAuthComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ClientCaptureComponent,
    BankEmployerDetailsComponent,
    BrokerDashboardComponent,
    BatchUploadComponent,
    LineChartComponent,
    BarChartXComponent,
    BatchDialogComponent,
    BarChartComponent,
    LoanComponent,
    LoanPendingApprovalComponent,
    LoanDialogComponent,
    NotesComponent,
    ProductSelectorComponent
  ],
  exports: [
    NavbarAuthComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ClientCaptureComponent,
    BankEmployerDetailsComponent,
    BrokerDashboardComponent,
    BatchUploadComponent,
    BatchDialogComponent,
    LoanComponent,
    LoanPendingApprovalComponent,
    LoanDialogComponent,
    NotesComponent,
    ProductSelectorComponent
  ],
  providers: [
    // { provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}}
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ], 
  entryComponents: [
    LoanDialogComponent
  ]
})
export class BrokerComponentsModule { }
