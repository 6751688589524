<div class="container-fluid">
    <div class="form-position">
        <form>
            <div class="row p-4">
                <h3 class="card-title"><b>Client has been successfully captured</b></h3>
            </div>
            <div class="row row-padding">
                <p style="display:inline-block;">Congratulations you have successfully submitted your application please proceed with your next customer</p>
            </div>
            <div class="row row-padding">
                <br>
                <br>
                <button mat-fab class="form-button-center" style="box-shadow: none;" type="submit" (click)="finish()"><i class="fa fa-plus"></i></button>
            </div>
        </form>
    </div>