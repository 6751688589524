<div class="container-fluid">
    <div class="form-position mt-2">
        <div class="justify-space-around">
            <div class="row row-cols-1 row-cols-md-2 mb-2">
                <div class="col order-0">

                    <div class="list-group">
                        <div (click)="selectDocument(i)" class="list-group-item list-group-item-action" [ngClass]="{'active': selectedDocument == doc.key}" *ngFor="let doc of documents; let i = index">
                            <mat-checkbox disabled="true" [checked]="doc.isUploaded"></mat-checkbox>
                            {{doc.description}}
                        </div>
                    </div>
                </div>
                <div class="col order-1">
                    <div class="fileDropZone">
                        <div for="file" ng2FileDrop (fileOver)="fileOverBase($event)" [uploader]="uploader" class="text-primary file-upload-label m-0 p-0">
                            <input type="file" ng2FileSelect [uploader]="uploader" multiple />
                            <h3>Drag and drop {{selectedDocumentDescription}} here</h3>
                            <h3>or</h3>
                            <label for="fileDropID">Browse for file</label>
                        </div>
                    </div>
                    <br />
                    <div class="files-list">
                        <p>Queue length: {{ uploader?.queue?.length }}</p>
                        <div class="single-file" *ngFor="let fileItem of uploader.queue; let i = index">
                            <div class="info">
                                <h4 class="name">
                                    {{ fileItem?.file?.name }}
                                </h4>
                                <p class="size" *ngIf="uploader.options.isHTML5" nowrap>
                                    {{ fileItem?.file?.size/1024/1024 | number:'.2' }} MB
                                </p>
                                <!-- <div class="progress-cont"> -->
                                <!-- <div class="progress-cont" *ngIf="fileItem?.progress < 100 && uploader.options.isHTML5"> -->
                                <div class="progress" style="margin-bottom: 0;">
                                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                                </div>
                                <!-- </div> -->
                            </div>
                            <i class="material-icons" title="Upload" style="cursor: pointer;" (click)="fileItem.upload()" *ngIf="!fileItem.isReady && !fileItem.isUploading && !fileItem.isSuccess">upload</i>
                            <i class="material-icons text-warning" title="Cancel" style="cursor: pointer;" (click)="fileItem.cancel()" *ngIf="fileItem.isUploading">cancel</i>
                            <i class="material-icons text-danger" title="Remove" style="cursor: pointer;" (click)="fileItem.remove()">delete</i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row row-padding">
            <button mat-fab class="form-button-left" style="box-shadow: none;" type="submit" (click)="back()"><i
                    class="fa fa-arrow-left"></i></button>
            <button mat-button class="form-button-right-submit" type="submit" (click)="finish()">Submit</button>
        </div>
    </div>
</div>