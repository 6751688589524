import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EmployerDetails, FindEmployer } from 'app/ts/models/broker-classes';
import { ApiService } from 'app/ts/ApiService';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BankEmployerDetailsResult } from 'app/ts/models/broker-interfaces';

@Component({
  selector: 'app-employer-details',
  templateUrl: './employer-details.component.html',
  styleUrls: ['./employer-details.component.scss', '../../forms.shared.scss']
})
export class EmployerDetailsComponent implements OnInit {

  salaryDates: number[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ]

  employerDetails = new EmployerDetails();
  employerFind = new FindEmployer();

  empDetails: FormGroup;
  currentRoute: string;
  dateParts: any[];
  clientId: number;
  result = {} as BankEmployerDetailsResult;

  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this.setEmployerDetailsForm();
  }

  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();
  @Output() sendEmployerDetailsResult = new EventEmitter<BankEmployerDetailsResult>();
  @Output() employerForm = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.empDetails.valueChanges.subscribe((x) => {
      if (this.empDetails.valid) {
        this.employerForm.emit(this.empDetails.valid);
      } 
    })
  }

  ngAfterViewChecked(): void {
    this.paramsChanged();
  }

  paramsChanged() {
    this.route.params.subscribe(params => {
      if (this.clientId != params['clientId']) {
        this.clientId = +params['clientId'];
        sessionStorage.setItem('searchClientID', this.clientId.toString());

        if (this.clientId != 0) {
          this.populateSearchEmployer();
        }else {
          this.setEmployerDetailsForm();
        }
      }
    });
  }

  populateSearchEmployer() {

    var clientIdSaved = sessionStorage.getItem('searchClientID');

    if (clientIdSaved != '0' && clientIdSaved != null) {
      this.employerFind = JSON.parse(sessionStorage.getItem('EmployerDetailsResult'));

      this.employerDetails.clientId = this.employerFind.ClientId;
      this.employerDetails.employer_Name = this.employerFind.Employer_Name;
      this.employerDetails.employer_Contact_Number = this.employerFind.Employer_Contact_Number;
      this.employerDetails.employee_Number = this.employerFind.Employee_Number;
      this.employerDetails.employment_Start_Date = this.employerFind.Employment_Start_Date;
      this.employerDetails.gross_salary = this.employerFind.Gross_Salary;
      this.employerDetails.net_salary = this.employerFind.Net_Salary;
      this.employerDetails.employer_Salary_Date = this.employerFind.Employer_Salary_Date;

      let d = this.employerFind.Employment_Start_Date.split("/");
      let startDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);

      this.empDetails.controls.employer_Name.patchValue(this.employerDetails.employer_Name);
      this.empDetails.controls.employer_Contact_Number.patchValue(this.employerDetails.employer_Contact_Number);
      this.empDetails.controls.employee_Number.patchValue(this.employerDetails.employee_Number);
      this.empDetails.controls.employment_Start_Date.patchValue(startDate);
      this.empDetails.controls.gross_salary.patchValue(this.employerDetails.gross_salary);
      this.empDetails.controls.net_salary.patchValue(this.employerDetails.net_salary);
      this.empDetails.controls.employer_Salary_Date.setValue(this.salaryDates[parseInt(this.employerDetails.employer_Salary_Date) - 1]);

      var loanId = parseInt(sessionStorage.getItem('loanStatusId'));

      sessionStorage.setItem('searchClientID', '');

      this.empDetails.valueChanges.subscribe((x) => {
        if (this.empDetails.valid) {
          this.employerForm.emit(this.empDetails.valid);
        } 
      })

      if (loanId == 300) {
        this.empDetails.disable();
      }
      else 
      {
        this.empDetails.enable();
      }

    }
    else
    {

      this.empDetails.enable();

      sessionStorage.removeItem('EmployerDetailsResult');

      this.empDetails.controls.employer_Name.patchValue('');
      this.empDetails.controls.employer_Contact_Number.patchValue('');
      this.empDetails.controls.employee_Number.patchValue(0);
      // this.empDetails.controls.employment_Start_Date.patchValue(startDate);
      this.empDetails.controls.gross_salary.patchValue(0);
      this.empDetails.controls.net_salary.patchValue(0);
      this.empDetails.controls.employer_Salary_Date.setValue(1);

    }

  }

  setEmployerDetailsForm() {
    this.empDetails = this.formBuilder.group({
      employer_Name: ['', Validators.required],
      employer_Contact_Number: ['', Validators.required],
      employee_Number: [0, Validators.required],
      employment_Start_Date: ['', Validators.required],
      employer_Salary_Date: ['', Validators.required],
      gross_salary: [0, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(10), Validators.min(0)])],
      net_salary: [0, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(10), Validators.min(0)])],
    });
  }


  save() {
    if (this.empDetails.valid) {
     
      this.employerDetails.clientId = Number(sessionStorage.getItem('clientId'));
      this.employerDetails.employer_Name = this.empDetails.controls['employer_Name'].value;
      this.employerDetails.employer_Contact_Number = this.empDetails.controls['employer_Contact_Number'].value;
      this.employerDetails.employee_Number = this.empDetails.controls['employee_Number'].value;
      this.employerDetails.employment_Start_Date = this.empDetails.controls['employment_Start_Date'].value;
      this.employerDetails.gross_salary = this.empDetails.controls['gross_salary'].value;
      this.employerDetails.net_salary = this.empDetails.controls['net_salary'].value;
      this.employerDetails.employer_Salary_Date = this.empDetails.controls['employer_Salary_Date'].value;

      this.api.saveEmployerDetails(this.employerDetails).subscribe((data: any) => {
        this.result.message = data.message;
        this.result.saved = data.message == "Ok" ? true : false;
      
        this.sendEmployerDetailsResult.emit(this.result);
      });
    }
    else 
    {
      this.openSnackBar("Please make sure the Employee details are entered correctly", 'error-snackbar');
    }
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  back(): void {
    this.previousStepEvent.emit();
  }

}
