import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BankDetailsComponent } from 'app/forms/broker-portal/bank-details/bank-details.component';
import { EmployerDetailsComponent } from 'app/forms/broker-portal/employer-details/employer-details.component';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { BankEmployerDetailsResult } from 'app/ts/models/broker-interfaces';

@Component({
  selector: 'app-bank-employer-details',
  templateUrl: './bank-employer-details.component.html',
  styleUrls: ['./bank-employer-details.component.scss']
})
export class BankEmployerDetailsComponent implements OnInit, AfterViewInit {

  constructor(private snackbar: MatSnackBar) { }

  @Output() eventChange = new EventEmitter<number>();
  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();

  @ViewChild(BankDetailsComponent) bankDetails: BankDetailsComponent;
  @ViewChild(EmployerDetailsComponent) employerDetails: EmployerDetailsComponent;

  banksSaved: boolean = false;
  employmentSaved: boolean = false;
  bankFormValid: boolean = false;
  employerFormValid: boolean = false;
  results: BankEmployerDetailsResult;


  ngOnInit() {

  }

  ngAfterViewInit(): void {

  }

  save(): void {
    this.bankDetails.save();
    this.employerDetails.save();
  }

  back(): void {
    this.previousStepEvent.emit();
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  getBankDetailsResult(result: BankEmployerDetailsResult) {
    if (result.message == "Ok" || result.message == "Success") {
      this.banksSaved = result.saved;
      //this.snackbarTrigger("Bank details saved", false);
      this.nextPage();
    }
    else {
      this.snackbarTrigger(result.message, true);
    }
  }

  getEmployerDetailsResult(result: BankEmployerDetailsResult) {
    if (result.message == "Ok") {
      this.employmentSaved = result.saved;
      //this.snackbarTrigger("Employement details saved", false);
      this.nextPage();
    }
    else {
      this.snackbarTrigger(result.message, true);
    }
  }

  snackbarTrigger(msg: string, error: boolean) {
    if (error) {
      this.openSnackBar(msg, 'error-snackbar');
    } else {
      this.openSnackBar(msg, 'success-snackbar');
    }
  }

  nextPage() {
    if (this.banksSaved && this.employmentSaved) {
      this.snackbarTrigger("Bank and Employer details saved", false);
      this.eventChange.emit();
      this.updateStepEvent.emit();
    }
  }

  isBankFormValid(result: boolean) {
    this.bankFormValid = result;
    console.log(this.bankFormValid);
  }

  isEmployerFormValid(result: boolean) {
    this.employerFormValid = result;
    console.log(this.employerFormValid);
  }

}
